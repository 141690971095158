import React from 'react';
import { ResponsiveLine } from '@nivo/line'

class AmsterdamLineChart extends React.Component {
    render() {
        return (
            <div style={{height: '100%'}}>
                <p className='chart-comment'>{this.props.comment}</p>
                <p className='chart-header'>{this.props.header}</p>
                <p className='chart-subheader'>{this.props.subheader}</p>

                <ResponsiveLine
                    data={this.props.data}
                    curve='monotoneX'
                    margin={{ top: 20, right: 20, bottom: 60, left: 50 }}
                    colors={this.props.color}
                    lineWidth={2.5}
                    pointSize={this.props.pointSize}
                    pointBorderColor="#fff"
                    pointBorderWidth={2}
                    enableGridX={false}
                    axisLeft={this.props.axisLeft}
                    axisBottom={this.props.axisBottom}
                    yScale={this.props.yScale}
                    legends={this.props.legends}
                    useMesh={this.props.useMesh}
                    enableSlices={this.props.enableSlices}
                    theme={{
                        axis: {
                            ticks: {
                                text: { fontSize: '14px', fontFamily: 'Roboto Slab' }
                            }
                        }
                    }}
                />
            </div>
        );
    }
}

export default AmsterdamLineChart;