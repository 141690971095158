import React from 'react';
import SEO from "../components/seo"
import { Link } from 'gatsby'
import amsterdamData from '../data/amsterdam/amsterdam_data.json'
import HeaderCentered from '../components/Header/HeaderCentered'
import '../styles/amsterdam.css'
import SubscribeBox from '../components/SubscribeBox/SubscribeBox';
import ShareButtons from '../components/ShareButtons/ShareButtons';
import ArticleHeader from '../components/Article/ArticleHeader';
import ArticleParagraph from '../components/Article/ArticleParagraph';
import ArticleRecommender from '../components/ArticleRecommender/ArticleRecommender'
import AmsterdamLineChart from '../components/Amsterdam/AmsterdamLineChart';
import amsterdamSocial from '../images/amsterdam/amsterdam-thumbnail.jpeg'

class Amsterdam extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            windowHeight: 800,
        }

        this.resize = this.resize.bind(this);
    }

    componentDidMount() {
        this.resize();

        window.addEventListener('resize', this.resize)
    }

    resize() {
        this.setState({
            windowHeight: window.innerHeight
        })
    }

    render() {

        return (
            <div className='amsterdam'>
                <SEO
                    title="Amsterdam, a drowning city"
                    description="The rise of Amsterdam's overtourism and how the coronavirus pandemic let the city breathe again."
                    pathname='/amsterdam'
                    image={{ src: amsterdamSocial, width: 1200, height: 600 }} />
                <HeaderCentered />

                <article className="cf ph3 ph5-ns pv4">
                    <ArticleHeader
                        title='Amsterdam, a drowning city'
                        author='Dimitris Michailidis'
                        date='October 5, 2020' />
                    <div className="fn fl-ns w-50-l center">
                        <ArticleParagraph text="In 2019, more than 19 million tourists descended in Amsterdam, a city of 820,000 inhabitants. Despite the government's efforts to combat overtourism, the number of visitors keeps increasing year after year, and Amsterdamers - otherwise famous for their tolerant culture - are starting to lose their patience." />
                        <ArticleParagraph text="In this story, we explore the evolution of mass tourism in Amsterdam and how the coronavirus pandemic has become an unexpected ally in the city's efforts to breathe again." />
                    </div>
                </article>

                <section className='chart-section' style={{ height: this.state.windowHeight * 0.5, top: this.state.windowHeight * 0.01 }}>
                    <AmsterdamLineChart
                        data={amsterdamData[0]}
                        comment='Within 10 years the number of passengers at the Schiphol airport in Amsterdam nearly doubled, only to go down by 64% during the pandemic.'
                        header='Number of Passengers in Amsterdam Schiphol Airport'
                        subheader='(First half of the year, excluding transfer passengers)'
                        color='#0078FF'
                        pointSize={8}
                        axisLeft={{
                            format: value =>
                                `${value / 1000000} M`,
                        }}
                        axisBottom={{
                            tickValues: ['2000', '2004', '2008', '2012', '2016', '2020'],
                            legendOffset: -12,
                        }} />
                </section>

                <section className='chart-section' style={{ height: this.state.windowHeight * 0.5, top: this.state.windowHeight * 0.01 }}>
                    <AmsterdamLineChart
                        data={amsterdamData[1]}
                        comment='From 2012 to 2019, the number of overnight stays in Amsterdam increased by 80% to 9 Million, before dropping back to 3.4 Million in 2020.'
                        header='Total Number of Overnight Stays in Amsterdam'
                        subheader='(First half of the year)'
                        color='#0078FF'
                        pointSize={8}
                        axisLeft={{
                            format: value =>
                                `${value / 1000000} M`,
                        }}
                    />
                </section>

                <section className='chart-section' style={{ height: this.state.windowHeight * 0.5, top: this.state.windowHeight * 0.01 }}>
                    <AmsterdamLineChart
                        data={amsterdamData[2]}
                        comment="Airbnb's growth contributed to the rise in tourism. In 2019, 20.000 rooms were available monthly for short-term rent in Amsterdam, almost twice the amount of listings 3 years ago."
                        header='Average Monthly Available Airbnb Rooms'
                        subheader='(First half of the year)'
                        color='#0078FF'
                        pointSize={8}
                    />
                </section>

                <section className='chart-section' style={{ height: this.state.windowHeight * 0.5, top: this.state.windowHeight * 0.01 }}>
                    <AmsterdamLineChart
                        data={amsterdamData[3]}
                        comment='But holidays did not get cheaper. Even in 2020, when demand tanked, Airbnb prices kept increasing, with rooms in Amsterdam costing 164€ on average - 27% higher than in 2015.'
                        header='Average Monthly Airbnb Room Price (€)'
                        subheader='(First half of the year)'
                        color='#0078FF'
                        pointSize={8}
                    />
                </section>

                <section className='chart-section' style={{ height: this.state.windowHeight * 0.5, top: this.state.windowHeight * 0.01 }}>
                    <AmsterdamLineChart
                        data={amsterdamData[5]}
                        comment='As Airbnb prices go up, so do rental prices. The average rent in Amsterdam in 2020 was 44% higher than 10 years ago. In comparison, other major cities saw an average increase of 30% since 2010.'
                        header='% Change in Rental Prices compared to H1 2010'
                        subheader='€ per square meter'
                        color={{ scheme: 'category10' }}
                        pointSize={0}
                        useMesh={true}
                        enableSlices={'x'}
                        yScale={{
                            type: 'linear',
                            min: -10,
                            max: 50,
                        }}
                        axisLeft={{
                            format: value =>
                                `${value} %`,
                        }}
                        axisBottom={{
                            tickValues: ['Q1 2010', 'Q1 2012', 'Q1 2014', 'Q1 2016', 'Q1 2018', 'Q1 2020'],
                        }}
                        legends={[
                            {
                                itemWidth: 80,
                                itemHeight: 20,
                                direction: 'column',
                                translateX: '10'
                            }
                        ]}
                    />
                </section>

                <section className='chart-section' style={{ height: this.state.windowHeight * 0.5, top: this.state.windowHeight * 0.01 }}>
                    <AmsterdamLineChart
                        data={amsterdamData[4]}
                        comment="Citizens are not only concerned about rental prices, but also about the quality of incoming tourists. While overnight stays in Amsterdam increased by 45% since 2015, the city's museums only saw 14% more visitors on their premises."
                        header="Total Visitors in Amsterdam's  Museums"
                        subheader=''
                        color='#0078FF'
                        pointSize={8}
                        axisLeft={{
                            format: value =>
                                `${value / 1000000} M`,
                        }}
                    />
                </section>

                <article className="cf ph3 ph5-ns pv4">
                    <div className="fn w-50-l center">
                        <ArticleParagraph text="If you took a walk around the center of Amsterdam during the sunny weeks of March, you would see an unprecedented picture. Young children playing in the middle of Dam Square, couples picnicking at the entrance of the Rijksmuseum, people meditating in front of Centraal Station. Since then, borders have opened up again, visitors are back, but the hope that the mass tourism madness is left behind is still alive." />

                    <h3 className="f4 lh-title">Methodology</h3>
                    <p className="f5 lh-copy measure mt0-ns center tj">Data for passengers, overnight stays and museum visitors were collected from the <a href="https://data.amsterdam.nl/datasets/zoek/" target="_blank" rel="noopener noreferrer">Municipality of Amsterdam data portal</a>.</p>
                    <p className="f5 lh-copy measure mt0-ns center tj">Data for Airbnb were collected from <a href="http://insideairbnb.com/get-the-data.html" target="_blank" rel="noopener noreferrer">Inside Airbnb</a>.</p>
                    <p className="f5 lh-copy measure mt0-ns center tj">Data for rental prices were collected from <a href="https://www.statista.com/statistics/612227/average-rent-in-four-largest-cities-in-the-netherlands-by-city/" target="_blank" rel="noopener noreferrer">Pararius</a>.</p>

                    <h3 className="f4 lh-title mt5">Enjoyed the story? Subscribe for more:</h3>
                    <SubscribeBox/>
                    </div>

                    <ShareButtons url="https://www.thousandwords.blog/amsterdam"></ShareButtons>
                </article>

                <ArticleRecommender articles={['rebel-writers', 'biggest-concerts']} />

                <footer className="pv4 ph3 ph5-m ph6-l mid-gray">
                    <small className="f6 db tc">© 2020 <b className="ttu">Thousand Words</b>. All Rights Reserved</small>
                    <div className="tc mt3">
                        <Link to='/' className="f6 dib ph2 link mid-gray dim">home</Link>
                    </div>
                </footer>
            </div>
        );
    }
}

export default Amsterdam;